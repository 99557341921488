<template>
  <div class="notificator no-select">
    <transition-group name="slide-fade">
      <div
        v-for="item in list"
        v-bind:key="item.id"
        class="notificator--item"
        v-bind:class="{
          not_danger: item.color == 'danger',
          not_success: item.color == 'success'
        }"
      >
        <div @click="removeNotification(item)" class="notificator--close">
          <i class="fas fa-times"></i>
        </div>
        {{ item.text }}
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Notificator",
  computed: {
    ...mapState({
      list: state => state.notificator.list
    })
  },
  methods: {
    removeNotification(item) {
      this.$store.dispatch("removeNotification", item.id);
    }
  }
};
</script>

<style scoped lang="css">
.notificator {
  position: fixed;
  z-index: 99999999999999;
  top: 20px;
  right: 20px;
}
.notificator--item {
  width: 270px;
  text-align: center;
  padding: 18px;
  box-sizing: border-box;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  position: relative;
}
.notificator--close {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 9px;
}
.not_danger {
  background-color: #dc3545;
}
.not_success {
  background-color: #28a745;
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
