<script>
import simplebar from "simplebar-vue";
import i18n from "../i18n";
import { layoutComputed } from "@/state/helpers";
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      legal_name: state => state.legal_name.legal_name,
      legal_request: state => state.legal_name.request
    }),
    ...layoutComputed,
    currentFlagPath: function(){
      let result = '';
      //i18n.locale
      for(let key in this.languages){
        if(i18n.locale == this.languages[key].language){
          result = this.languages[key].flag;
        }
      }
      return result;
    }
  },
  components: { simplebar },
  data() {
    return {
      userEmail: '',
      languages: [
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: "ru",
    };
  },
  methods: {
    getLegalName(){
      axios
      .get(`/v1/users/my-legal-name`)
      .then(resp => {
        this.$store.dispatch('setLegalName', resp.data.name);
        this.legalName = resp.data.name;
      })
      .catch(err => {
        this.$store.dispatch("addNotification", {
          text: err.response.data.message,
          time: 6,
          color: "danger"
        });
      });
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    setLanguage(locale) {
      let reloadPage = false;
      if(i18n.locale != locale){
        reloadPage = true;
      }
      i18n.locale = locale;
      this.current_language = i18n.locale;
      localStorage.setItem("locale", locale);
      if(reloadPage){
        location.reload();
      }
    },
  },
  mounted() {
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
    let userData = JSON.parse(localStorage.getItem("userData"));
    this.userEmail = userData[0].email;

    let locale = localStorage.getItem("locale");
    if(locale){
      this.setLanguage(locale);
    }

    if(this.legal_request == 0){
      setTimeout(this.getLegalName, 1200);
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "dark":
              document.body.setAttribute("data-topbar", "dark");
              break;
            case "light":
              document.body.setAttribute("data-topbar", "light");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            case "colored":
              document.body.setAttribute("data-topbar", "colored");
              document.body.removeAttribute("data-layout-size", "boxed");
              break;
            default:
              document.body.setAttribute("data-topbar", "dark");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-mode", "fluid");
              document.body.removeAttribute("data-layout-size");
              break;
            default:
              document.body.setAttribute("data-layout-mode", "fluid");
              break;
          }
        }
      },
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
<!--          <a href="index.html" class="logo logo-dark">-->
<!--            <span class="logo-sm">-->
<!--              <img src="@/assets/images/logo-sm-dark.png" alt height="22" />-->
<!--            </span>-->
<!--            <span class="logo-lg">-->
<!--              <img src="@/assets/images/logo-dark.png" alt height="20" />-->
<!--            </span>-->
<!--          </a>-->

          <a href="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-white.svg" alt height="26" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-white.svg" alt height="26" />
              <!-- <span style="display: inline-block; width: 8px;"></span>
              <img src="@/assets/images/logo-big.svg" alt height="20" /> -->
            </span>
            <span v-if="legal_name !== ''" class="ip-span"> | {{legal_name}}</span>
          </a>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 d-lg-none header-item"
          data-toggle="collapse"
          @click="toggleMenu"
        >
          <i class="ri-menu-2-line align-middle"></i>
        </button>

        <!-- App Search-->
<!--        <form class="app-search d-none d-lg-block">-->
<!--          <div class="position-relative">-->
<!--            <input-->
<!--              type="text"-->
<!--              class="form-control"-->
<!--              :placeholder="$t('navbar.search.text')"-->
<!--            />-->
<!--            <span class="ri-search-line"></span>-->
<!--          </div>-->
<!--        </form>-->


      </div>

      <div class="d-flex">
<!--        <div class="dropdown d-inline-block d-lg-none ml-2">-->
<!--          <button-->
<!--            type="button"-->
<!--            class="btn header-item noti-icon waves-effect"-->
<!--            id="page-header-search-dropdown"-->
<!--            data-toggle="dropdown"-->
<!--            aria-haspopup="true"-->
<!--            aria-expanded="false"-->
<!--          >-->
<!--            <i class="ri-search-line"></i>-->
<!--          </button>-->
<!--          <div-->
<!--            class="dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"-->
<!--            aria-labelledby="page-header-search-dropdown"-->
<!--          >-->
<!--            <form class="p-3">-->
<!--              <div class="form-group m-0">-->
<!--                <div class="input-group">-->
<!--                  <input-->
<!--                    type="text"-->
<!--                    class="form-control"-->
<!--                    placeholder="Search ..."-->
<!--                  />-->
<!--                  <div class="input-group-append">-->
<!--                    <button class="btn btn-primary" type="submit">-->
<!--                      <i class="ri-search-line"></i>-->
<!--                    </button>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </form>-->
<!--          </div>-->
<!--        </div>-->
<!--locale-->
       <b-dropdown variant="white" right toggle-class="header-item">
         <template v-slot:button-content>
           <img
             class
             :src="currentFlagPath"
             alt="Header Language"
             height="16"
           />
         </template>
         <b-dropdown-item
           class="notify-item"
           v-for="(entry, i) in languages"
           :key="`Lang${i}`"
           :value="entry"
           @click="setLanguage(entry.language)"
           :link-class="{ active: entry.language === current_language }"
         >
           <img
             :src="`${entry.flag}`"
             alt="user-image"
             class="mr-1"
             height="12"
           />
           <span class="align-middle">{{ entry.title }}</span>
         </b-dropdown-item>
       </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ml-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
            @click="initFullScreen"
          >
            <i class="ri-fullscreen-line"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0"
          toggle-class="header-item noti-icon"
          variant="black"
        >
          <!-- <template v-slot:button-content>
            <i class="ri-notification-3-line"></i>
            <span class="noti-dot"></span>
          </template> -->
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  Текст
                </h6>
              </div>
              <div class="col-auto">
                <a href="#!" class="small">Сабтекст</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 230px">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="ri-shopping-cart-line"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">
                    Заказ.Заголовок
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      Заказ.текст
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      Заказ.время
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </simplebar>
          <div class="p-2 border-top">
            <a
              class="btn btn-sm btn-link font-size-14 btn-block text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle mr-1"></i>
              Кнопка
            </a>
          </div>
        </b-dropdown>

        <b-dropdown
          right
          variant="black"
          toggle-class="header-item"
          class="d-inline-block user-dropdown"
        >
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/incognito.png"
              alt="Header Avatar"
            />
            <span class="d-none d-xl-inline-block ml-1">{{
                userEmail
            }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->
<!--          <a class="dropdown-item" href="#">-->
<!--            <i class="ri-user-line align-middle mr-1"></i>-->
<!--            {{ $t("navbar.dropdown.kevin.list.profile") }}-->
<!--          </a>-->
<!--          <a class="dropdown-item" href="#">-->
<!--            <i class="ri-wallet-2-line align-middle mr-1"></i>-->
<!--            {{ $t("navbar.dropdown.kevin.list.mywallet") }}-->
<!--          </a>-->
<!--          <a class="dropdown-item d-block" href="#">-->
<!--            <span class="badge badge-success float-right mt-1">11</span>-->
<!--            <i class="ri-settings-2-line align-middle mr-1"></i>-->
<!--            {{ $t("navbar.dropdown.kevin.list.settings") }}-->
<!--          </a>-->
<!--          <a class="dropdown-item" href="#">-->
<!--            <i class="ri-lock-unlock-line align-middle mr-1"></i>-->
<!--            {{ $t("navbar.dropdown.kevin.list.lockscreen") }}-->
<!--          </a>-->
          <div class="dropdown-divider"></div>
          <div @click="logout" class="dropdown-item text-danger" style="cursor: pointer;">
            <i class="ri-shut-down-line align-middle mr-1 text-danger"></i>
            {{ $t("navbar.dropdown.user.logout") }}
          </div>
        </b-dropdown>

<!--        <div class="dropdown d-inline-block">-->
<!--          <button-->
<!--            type="button"-->
<!--            class="btn header-item noti-icon right-bar-toggle waves-effect toggle-right"-->
<!--            @click="toggleRightSidebar"-->
<!--          >-->
<!--            <i class="ri-settings-2-line toggle-right"></i>-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
  .logo-lg{
    display: inline-block;
  }
  .ip-span{
    font-size: 12px;
    margin-left: 6px;
    display: inline-block;
    color: #fff;
  }
</style>
