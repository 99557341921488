<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ new Date().getFullYear() }} © Copix
        </div>
        <div class="col-sm-6 text-right">
          <div @click="showTgbotPopup" class="btn btn-sm btn-info">
            {{$t('views.footer.settingBotBtn')}}
            <i class="fab fa-telegram-plane"></i>
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="tgbotPopup.closeButton"
      :show="tgbotPopup.show"
      @closePopup="closeTgbotPopup"
    >
      <div slot="header">{{$t('views.footer.settingTgBot')}}</div>
        <div slot="body">
          <div>{{$t('views.footer.textOne')}}</div>
          <div class="mt-3">
            <div class="row">
              <div class="col-lg-12">
                {{$t('views.footer.textTwo')}}
              </div>
            </div>
            <div class="row">
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-8 mt-1">
                    <div class="form-group">
                      <!-- <label for="bot_token">{{$t('views.footer.textTwo')}}</label> -->
                      <input
                          id="bot_token"
                          v-model="regCommand"
                          type="text"
                          class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 mt-1">
                    <div class="form-group text-right">
                      <div
                        @click="copyText(regCommand)"
                        class="btn btn-sm btn-secondary d-block"
                      >
                        {{$t('popularWords.copy')}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-12">
                    <a
                      href="https://t.me/copixrobot"
                      target="_blank"
                      class="btn btn-sm btn-info d-block"
                    >
                      {{$t('views.footer.goToBot')}}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-lg-1">
                <img width="30" src="@/assets/images/icons/clock_alert.png">
              </div>
              <div class="col-lg-11">
                {{$t('views.footer.timeInst')}}
              </div>
            </div>

          </div>
        </div>
    </popup>

  </footer>
</template>

<script>
import Popup from './Popup.vue';
import axios from "axios";

/**
 * footer
 */
export default {
  components: {
    Popup
  },
  data() {
    return {
      regCommand: '',
      tgbotPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
    };
  },
  computed: {

  },
  created: () => {

  },
  methods: {
    closeTgbotPopup(){
      this.tgbotPopup.show = false;
    },
    showTgbotPopup(){
      axios
          .post(`/v1/telegram/generate-token`)
          .then(resp => {
           if(resp.data.result == 'ok'){
            this.regCommand = resp.data.token;
            this.tgbotPopup.show = true;
           }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    copyText(text){
      navigator.clipboard.writeText(text)
          .then(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('views.footer.commandToCoppy'),
              time: 3,
              color: "success"
            });
          })
          .catch(() => {
            this.$store.dispatch("addNotification", {
              text: this.$t('views.footer.copyNoSupport'),
              time: 3,
              color: "danger"
            });
          });
    },
  },
  mounted() {

  },
};
</script>
