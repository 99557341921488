<template>
  <div v-if="isShow" class="preloader">
    <div class="preloader--center">
        <span class="m-2 spinner-border text-warning"></span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Preloader",
  computed: {
    ...mapState({
      isShow: state => state.preloader.isShow
    })
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.preloader {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 200000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;

  & > .preloader--center {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
  }
}
</style>
